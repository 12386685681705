import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { Observable, filter, map, take } from 'rxjs'
import { UserService } from '../../../core/services/user.service'
import { PlanSelectedGuard } from '../../../core/guards/plan-selected.guard'
import { CartService } from './cart.service'
import { RouteHeaderUrl } from '../../../shared/components/one-header/header-navigation.model'
import { ShopifyService } from '../../../core/services/shopify.service'

@Injectable({
  providedIn: 'root'
})
export class StripeRedirectGuardService  {
  forbiddenPlans = inject(PlanSelectedGuard).hideStripePlansFrom
  forceStripePricing = inject(CartService).forceStripePricing
  constructor(
    private userService: UserService,
    private shopifyService: ShopifyService,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.shopifyService.activeKnownCustomersCount$.pipe(
      filter((akcs) => {
        if (this.userService.isShopifyShop) {
          return akcs !== null
        } else {
          return true
        }
      }),
      take(1),
      map((akcs) => {
        const canGoCustom = !this.forbiddenPlans.includes(this.userService?.userInfo?.shop?.profile?.plan_name)
        if (this.userService.isShopifyShop && akcs > 5000 && (canGoCustom || this.forceStripePricing)) {
          this.router.navigate([state.url + `/${RouteHeaderUrl.custom}`])
          return false
        }
        if (this.forceStripePricing) {
          this.router.navigate([state.url + `/${RouteHeaderUrl.custom}`])
          return false
        }
        return true
      })
    )
  }
}
