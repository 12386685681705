import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { forkJoin, Observable, Subscription } from 'rxjs'
import { StoreState } from '../../store/store.state'
import { getUserChoseInitialPlanCompleted, getUserInfo, getUserShop } from '../../store/user/user.selectors'
import { filter, finalize, map, take } from 'rxjs/operators'
import { first } from 'rxjs/operators'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { Logger } from '../services/logger.service'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { UserShop } from '../../shared/models/user/user-shop.model'
import { UserInfo } from '../../store/user/user.state'
import { PromoService } from '../../shared/modules/promo/services/promo.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { GettingStartedStatus } from '../../shared/models/getting-started/getting-started-status.model'
import { getGettingStartedStatus } from '../../store/getting-started/getting-started.selectors'
import { SafeLocalStorageService } from '../services/safe-local-storage.service'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
import { CartService } from '../../pages/cart/services/cart.service'
import { ProductPageConfig } from '../../pages/cart/services/products-page-config'
import { UserService } from '../services/user.service'
import { ShopifyService } from '../services/shopify.service'

@Injectable()
export class PlanSelectedGuard  {
  subscription = new Subscription()
  productPageConfig = new ProductPageConfig()
  hideStripePlansFrom = [
    'partner_test',
    'affiliate',
    'staff',
    'staff_business',
    'shopify_alumni',
  ]
  showFreePlan = [
    'partner_test',
    'affiliate',
    'staff',
    'staff_business',
  ]

  userType = 'new'

  initialPaths = `/${RouteHeaderUrl.apps}`
  forceStripePricing = inject(CartService).forceStripePricing

  constructor(
    private router: Router,
    private store: Store<StoreState>,
    private logger: Logger,
    private userService: UserService,
    private promoService: PromoService,
    private safeLocalStorageService: SafeLocalStorageService,
    private shopifyService: ShopifyService,
  ) {
  }

  check(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.dispatch(new ShowLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
    return forkJoin([
      this.userChoseInitialPlanCompleted$,
      this.userShop$,
      this.userInfo$,
      this.gettingStartedStatus$,
      this.userInteractions$,
      this.shopifyService.activeKnownCustomersCount$.pipe(filter(akcs => {
        if (this.userService.isShopifyShop) {
          return akcs !== null
        } else {
          return true
        }
      }), take(1))
    ]).pipe(
      map(([choseInitialPlan, userShop, userInfo, status, interactions, akc]) => {
        this.logger.log(<LogLabel>'signup-completed-guard', 'choseInitialPlan: ', choseInitialPlan)
        if (!choseInitialPlan) {
          // If a user satisfies given criteria then skip plans page
          const shopifyPlan = userShop?.profile?.plan_name
          const isShopifyShop = this.userService.isShopifyShop
          // const isOnboarding = window.location.pathname.includes('onboarding/signup')
          const canGoCustom = isShopifyShop && !this.hideStripePlansFrom.includes(userInfo?.shop?.profile?.plan_name)

          // If user tries to navigate to custom stripe plan page - allow
          const allowedPaths = Object.values(this.productPageConfig.stripePricingPaths)
          if (( !this.hideStripePlansFrom.includes(shopifyPlan) && allowedPaths.some(p => state.url.includes(p)) ) || this.forceStripePricing) {
            return true
          }

          // if admin used an override to navigate as this user, let them go
          if (this.getAdminNavOverride(userInfo)) {
            return true
          }

          // If plan is not chosen and onboarding status is completed - user is returning
          this.userType = !status?.completed ? 'new' : 'returning'

          // FIXME: This is bad:
          // check if user has active stripe subscription 
          // since we have a bug where we can't update the plan name in the subscription object
          // we need to check if the subscription is active by looking into interactions data
          const stripeSubActive = interactions?.stripe_billing?.subscription
          // Redirect to custom stripe plan page if user is on onboarding and has more than 5000 active customers
          if (isShopifyShop && akc > 5000 && (canGoCustom || this.forceStripePricing)) {
            this.router.navigateByUrl(`/onboarding/signup/${this.userType}/subscriptions/custom`).then(() => {
              this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
            })
          // Redirect to Apps Page if not one of nonSkipPlans and user created after skipRange
          } else if (!!stripeSubActive) {
            return true
          // Redirect to plans page if shopifyPlan is in scholarshipPlans
          } else {
            if (!this.promoService.promoCookieSet(userInfo)) {
              this.promoService.togglePromoState(userInfo, true)
            }
            if (this.userType === 'new') {
              const surveyCompleted = status?.[`pre_plans_survey_${this.userType}`]?.completed
              if (surveyCompleted) {
                this.router.navigateByUrl(`/onboarding/signup/${this.userType}/subscriptions`).then(() => {
                  this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
                })
                return false
              } else {
                this.router.navigateByUrl(`/onboarding/signup/${this.userType}/welcome`).then(() => {
                  this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
                })
                return false
              }
            } else {
              this.router.navigateByUrl(`/onboarding/signup/returning/subscriptions`).then(() => {
                this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
              })
              return false
            }
          }
        }
        if (this.getAdminNavOverride(userInfo)) {
          return true
        } else {
          return choseInitialPlan
        }
      }),
      finalize(() => this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))),
    )
  }

  // If it's an admin that choose to override navigation for this specific user, let them go
  getAdminNavOverride(userInfo): boolean {
    const overrideForAdmin = this.safeLocalStorageService.getItem('one-override-uninstalled-behavior')
    return overrideForAdmin === userInfo.id
  }

  get userChoseInitialPlanCompleted$(): Observable<boolean> {
    return this.store.pipe(
      select(getUserChoseInitialPlanCompleted),
      filter(completed => completed !== undefined),
      first(),
    )
  }

  get userShop$(): Observable<UserShop> {
    return this.store.pipe(
      select(getUserShop),
      filter(shop => shop !== undefined),
      first(),
    )
  }

  get userInteractions$(): Observable<any> {
    return this.userService.interactions$.pipe(
      filter(interactions => !!interactions),
      first(),
    )
  }

  get userInfo$(): Observable<UserInfo> {
    return this.store.pipe(
      select(getUserInfo),
      filter(info => info !== undefined && !!info.created_at),
      first(),
    )
  }

  get gettingStartedStatus$(): Observable<GettingStartedStatus> {
    return this.store.pipe(
      select(getGettingStartedStatus),
      filter(next => next !== undefined),
      filter(status => !!status),
      first(),
    )
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.check(next, state)
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.check(next, state)
  }
}
