import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Observable, filter, map, take } from 'rxjs'
import { UserService } from '../../../core/services/user.service'
import { PlanSelectedGuard } from '../../../core/guards/plan-selected.guard'
import { UserInfo } from '../../../store/user/user.state'
import { CartService } from './cart.service'

@Injectable()
export class StripePlanGuardService  {
  forbiddenPlans = inject(PlanSelectedGuard).hideStripePlansFrom
  forceStripePricing = inject(CartService).forceStripePricing
  constructor(
    private userService: UserService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.userService.userInfo$.asObservable().pipe(
      filter(userInfo => !!userInfo?.shop),
      map((userInfo: UserInfo) => userInfo.shop.profile.plan_name),
      map((planName: string) => this.forceStripePricing || !this.forbiddenPlans.includes(planName) || this.userService.isStripeCustomer),
      take(1),
    )
  }
}
