import { Component, OnDestroy, OnInit } from '@angular/core'
import { UserService } from '../../../core/services/user.service'
import { CommonModule } from '@angular/common'
import { Subscription } from 'rxjs'
import { Router, RouterModule } from '@angular/router'
import { RouteHeaderUrl } from '../one-header/header-navigation.model'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../store/store.state'
import { ResetLoading } from '../../../store/loading/loading.actions'
import { AdminUserTokenService } from '../../../core/services/admin-user-token.service'
import { SafeLocalStorageService } from '../../../core/services/safe-local-storage.service'
import { MatDialog } from '@angular/material/dialog'
import { CartService } from '../../../pages/cart/services/cart.service'

@Component({
  selector: 'one-admin-tools',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './admin-tools.component.html',
  styleUrl: './admin-tools.component.scss',
})
export class AdminToolsComponent implements OnInit, OnDestroy {
  private subscription = new Subscription()
  public opened = false
  public userData = []
  public RouteHeaderUrl = RouteHeaderUrl
  public navigationOverridden = false
  public stripePricingForced = false
  public isStripeCustomer = false
  public consoleOutputEnabled = false
  public userAccountName

  constructor(
    private userService: UserService,
    private store: Store<StoreState>,
    private adminUserTokenService: AdminUserTokenService,
    private safeLocalStorageService: SafeLocalStorageService,
    private cartService: CartService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.subscription.add(
      this.userService.userInfo$.asObservable().subscribe((userInfo) => {
        const storeUrl = userInfo?.shop?.domain || userInfo?.shop?.store_url
        this.userData = [{
            key: 'id',
            title: 'ID',
            type: 'info',
            value: userInfo?.id
          },{
            key: 'email',
            title: 'Email',
            type: 'info',
            value: userInfo?.email
          },{
            key: 'access_token',
            title: 'Access Token',
            type: 'info',
            value: userInfo?.access_token
          },{
            key: 'name',
            title: 'Name',
            type: 'info',
            value: userInfo?.profile?.first_name + ' ' + userInfo?.profile?.last_name
          },{
            key: 'account_created',
            title: 'Date Created',
            type: 'info',
            value: userInfo?.created_at
          },{
            key: 'plan',
            title: 'Plan ID',
            type: 'info',
            value: userInfo?.subscription?.plan?.payment_gateway_plan_identifier
          },{
            key: 'plan_name',
            title: 'Plan Name',
            type: 'info',
            value: userInfo?.subscription?.plan?.name
          },
          {
            key: 'profile_link',
            title: 'Profile Link',
            type: 'link',
            value: `https://metabase.morpheusone.com/dashboard/103-one-store-profiles-beta?one_account_id=${userInfo.id}`,
            target: '_blank'
          },
          {
            key: 'logrocket_link',
            title: 'LogRocket',
            type: 'link',
            value: this.generateLogRocketLink(userInfo.email),
            target: '_blank'
          },
          {
            key: 'store_link',
            title: 'Store Link',
            type: 'link',
            value: storeUrl ? `https://${storeUrl}` : '',
            target: '_blank'
          }
        ]
        this.userAccountName = this.userService.getAccountName()
        this.isStripeCustomer = this.userService.isStripeCustomer
        this.stripePricingForced = this.cartService.forceStripePricing
        this.consoleOutputEnabled = this.safeLocalStorageService.getItem('isLoggerActive') === 'true'
      })
    )
  }

  ngOnInit(): void {
    this.navigationOverridden = this.isNavigationOverridden()
    if (this.safeLocalStorageService.localStorageAvailable) {
      window.addEventListener('storage', this.handleStorageChange.bind(this))
    }
  }

  isNavigationOverridden(): boolean {
    return !!(this.safeLocalStorageService.getItem('one-override-uninstalled-behavior') || 
    this.safeLocalStorageService.getItem('one-suppress-enforcement-popup'))
  }

  handleStorageChange(event: StorageEvent): void {
    if (event.key === 'one-override-uninstalled-behavior' || 
        event.key === 'one-suppress-enforcement-popup') {
      this.navigationOverridden = !!event.newValue
    }
  }

  removeLoadingOverlay(): void {
    this.store.dispatch(new ResetLoading())
  }

  logoutAsAdmin(): void {
    this.adminUserTokenService.logOutAsUser()
  }

  allowNavigation(): void {
    if (this.isNavigationOverridden()) {
      this.safeLocalStorageService.removeItem('one-override-uninstalled-behavior')
      this.safeLocalStorageService.removeItem('one-suppress-enforcement-popup')
    } else {
      this.safeLocalStorageService.setItem(
        'one-override-uninstalled-behavior', this.userService.userInfo.id
      )
      this.safeLocalStorageService.setItem(
        'one-suppress-enforcement-popup', this.userService.userInfo.id
      )
      this.dialog.closeAll()
    }
    this.navigationOverridden = this.isNavigationOverridden();
    this.router.navigate(['/'])
  }

  toggleStripePricing(): void {
    if (this.stripePricingForced) {
      this.safeLocalStorageService.removeItem('forceStripePricing')
    } else {
      this.safeLocalStorageService.setItem('forceStripePricing', 'true')
    }
    this.stripePricingForced = this.cartService.forceStripePricing
  }

  toggleConsoleOutput(): void {
    if (this.consoleOutputEnabled) {
      (window as any)?.disableOneConsoleLog()
    } else {
      (window as any)?.enableOneConsoleLog()
    }
    this.consoleOutputEnabled = this.safeLocalStorageService.getItem('isLoggerActive') === 'true'
  }

  copyToClipboard(value: string) {
    const selBox = document.createElement('textarea')
    selBox.style.position = 'fixed'
    selBox.style.left = '0'
    selBox.style.top = '0'
    selBox.style.opacity = '0'
    selBox.value = value
    document.body.appendChild(selBox)
    selBox.focus()
    selBox.select()
    document.execCommand('copy')
    document.body.removeChild(selBox)
  }

  generateLogRocketLink(email: string): string {
    const baseUrl = 'https://app.logrocket.com/morpheus/one';
    const filters = encodeURIComponent(JSON.stringify([
      {
        id: 'Hy5bW5l4yg',
        type: 'email',
        operator: {
          name: 'is',
          type: 'IS',
          hasStrings: true,
          autocompleteEnabled: true
        },
        strings: [email]
      }
    ]));
    const now = new Date();
    const rangeEnd = now.getTime();
    const rangeStart = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).getTime();
    const range = '1M';
    return `${baseUrl}?filters=${filters}&range=${range}&rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    if (this.safeLocalStorageService.localStorageAvailable) {
      window.removeEventListener('storage', this.handleStorageChange.bind(this))
    }
  }
}
