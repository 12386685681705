import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { StoreState } from '../../../store/store.state'
import { select, Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { Logger } from '../../../core/services/logger.service'
import { LogoutCurrentUser } from '../../../store/user/user.actions'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { Router, RouterModule } from '@angular/router'
import { PromoService } from '../../../shared/modules/promo/services/promo.service'
import { AdminUserTokenService } from '../../../core/services/admin-user-token.service'
import { GettingStartedService } from '../../../core/services/getting-started.service'
import { GettingStartedEventName } from '../../../shared/models/getting-started/getting-started-event-name.model'
import { GettingStartedEventState } from '../../../shared/models/getting-started/getting-started-event-state.model'
import { GettingStartedEventType } from '../../../shared/models/getting-started/getting-started-event-type.model'
import { LogLabel } from '../../../shared/models/logger/log-label.model'
import { getGettingStartedStatus } from '../../../store/getting-started/getting-started.selectors'
import { SafeLocalStorageService } from '../../../core/services/safe-local-storage.service'
import { UserService } from '../../../core/services/user.service'
import { ShopifyService } from '../../../core/services/shopify.service'
import { CommonModule } from '@angular/common'

@Component({
    selector: 'pf-log-out',
    templateUrl: './log-out.component.html',
    styleUrls: ['./log-out.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      RouterModule,
    ]
})
export class LogOutComponent implements OnInit, OnDestroy {

  @Input() position: string;
  @Input() entrance: 'pre-plan-survey' | '' = ''

  eventState: GettingStartedEventState
  subscription = new Subscription()
  loggedInAsAdmin = false
  disableLogout = false
  disableNavigation = false

  constructor(
    private gettingStartedService: GettingStartedService,
    private store: Store<StoreState>,
    private logger: Logger,
    private safeLocalStorageService: SafeLocalStorageService,
    private promoService: PromoService,
    protected afAuth: AngularFireAuth,
    private userService: UserService,
    private router: Router,
    private adminUserTokenService: AdminUserTokenService,
    private shopifyService: ShopifyService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store.pipe(select(getGettingStartedStatus))
        .subscribe(status => {
          this.eventState = this.gettingStartedService.getEventState(status)
          this.logger.log(LogLabel.GettingStartedComponent, 'EVENT STATE:', this.eventState)
        }),
    )
    this.loggedInAsAdmin = this.adminUserTokenService.getValue() !== null
    if (this.entrance === 'pre-plan-survey') {
      this.disableNavigation = true
      this.disableLogout = true
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  logout() {
    this.gettingStartedService.addEvent({
      type: GettingStartedEventType.Click,
      name: GettingStartedEventName.LogOut,
      state: this.eventState,
      metadata: {
        type: 'logout',
      },
    })
    this.promoService.clearCookiesAndStorage(this.userService.userInfo)
    this.afAuth.signOut()
      .then(() => {
        this.store.dispatch(new LogoutCurrentUser())
        if (this.safeLocalStorageService.getItem('pf-shopify-shop-user') || this.safeLocalStorageService.getItem('pf-shopify-shop-user-id')) {
          this.safeLocalStorageService.removeItem('pf-shopify-shop-user')
          this.safeLocalStorageService.removeItem('pf-shopify-shop-user')
        }
      }).catch((error) => {
      console.error('error logging out from log-out-componenet: ', error)
    })
    setTimeout(() => {
      this.router.navigateByUrl('/login')
    }, 0)
  }

  overrideUninstalledBehavior() {
    this.safeLocalStorageService.setItem(
      'one-override-uninstalled-behavior', this.userService.userInfo.id
    )
    this.router.navigate(['/'])
  }

  logoutAsAdmin() {
    if (this.entrance === 'pre-plan-survey') {
      this.adminUserTokenService.logOutAsUser()
    } else {
      this.safeLocalStorageService.setItem(
        'one-override-uninstalled-behavior', this.userService.userInfo.id
      )

      this.adminUserTokenService.logOutAsUser()
      // navigate to admin so it will fire the selectedUser action
      // this.shopifyService.redirectToUrl('/admin-panel')
    }
  }
}
