<div class="one-main-nav-item _is-user" tabindex="0">
  <a class="one-user-nav _nav-hidden-mobile">
    <span class="one-user-nav-name">{{ userAccountName }}</span> <one-icon class="ml-1" name="chevron-down"></one-icon>
  </a>
  <a class="one-user-nav _mobile">
    <one-icon class="fz28" name="person-circle-outline"></one-icon>
  </a>

  <div class="one-main-nav-dropdown _is-user">

    <a class="one-user-nav-menu-item"
      id="canny-button"
      href=""
      (click)="$event.preventDefault()"
      data-canny-changelog>
      <one-icon class="mr-1"name="newspaper-outline"></one-icon>
      What's New?
    </a>

    <a class="one-user-nav-menu-item" [href]="cannyUrl" target="_blank">
      <one-icon class="mr-1" name="chatbubbles-outline"></one-icon>
      Request Feature
    </a>

    <a class="one-user-nav-menu-item" [routerLink]="routeHeaderUrl.csm">
      <one-icon class="mr-1" name="call-outline"></one-icon>
      Schedule Call
    </a>

    <a class="one-user-nav-menu-item" href="#" (click)="openModal($event, 'ContactUsModalComponent')">
      <one-icon name="chatbox-ellipses-outline" class="mr-1"></one-icon>
      Message Us
    </a>

    <ng-container *ngIf="!shopType">
      <div class="one-user-nav-menu-divider"></div>
      <a class="one-user-nav-menu-item" [routerLink]="routeHeaderUrl.install">
        <one-icon name="code-working" class="mr-1"></one-icon>
        Install Pixel
      </a>
      <a class="one-user-nav-menu-item" [href]="brandingHelpUrl" target="_blank">
        <one-icon class="mr-1" name="help-circle-outline"></one-icon>
        Help Guides
      </a>
    </ng-container>

    <div class="one-user-nav-menu-divider"></div>

    <a class="one-user-nav-menu-item" [routerLink]="'/' + routeHeaderUrl.messaging+ '/' + routeHeaderUrl.emails + '/' + routeHeaderUrl.history">
      <one-icon name="mail-outline" class="mr-1"></one-icon>
      Email History
    </a>
    <a class="one-user-nav-menu-item" [routerLink]="'/' + routeHeaderUrl.messaging + '/' + routeHeaderUrl.emails + '/' + routeHeaderUrl.settings">
      <one-icon class="mr-1" name="cog"></one-icon>
      Email Settings
    </a>

    <div class="one-user-nav-menu-divider"></div>

    <ng-container *ngIf="isShopifyShop">
      <a class="one-user-nav-menu-item" [routerLink]="'/' + routeHeaderUrl.messaging + '/' + routeHeaderUrl.sms + '/' + routeHeaderUrl.history">
        <one-icon src="/app_assets/icons/phone-portrait-icon.svg" class="mr-1"></one-icon>
        SMS History
      </a>

      <a class="one-user-nav-menu-item" [routerLink]="'/' + routeHeaderUrl.messaging + '/' + routeHeaderUrl.sms + '/' + routeHeaderUrl.settings">
        <one-icon class="mr-1" name="cog"></one-icon>
        SMS Settings
      </a>

      <a
        class="one-user-nav-menu-item"
        mat-menu-item
        [routerLink]="routeHeaderUrl.sms_credits_page">
        <one-icon class="mr-1" name="wallet-outline"></one-icon>
        SMS Billing
      </a>

      <div class="one-user-nav-menu-divider"></div>

    </ng-container>

    <a class="one-user-nav-menu-item" (click)="navigateToPlansPage($event)">
      <one-icon class="mr-1" name="apps"></one-icon>
      Billing Plan
    </a>
    <div class="one-user-nav-menu-divider"></div>

    <a class="one-user-nav-menu-item" [routerLink]="routeHeaderUrl.settings" fragment="account-details">
      <one-icon class="mr-1" name="key"></one-icon>
      API Keys
    </a>

    <a class="one-user-nav-menu-item" [routerLink]="routeHeaderUrl.settings">
      <one-icon class="mr-1" name="person"></one-icon>
      Profile
    </a>


    <div class="one-user-nav-menu-divider"></div>

    <a *ngIf="!shopType"
      mat-menu-item
      class="one-user-nav-menu-item"
      [routerLink]="routeHeaderUrl.settings"
      fragment="login-methods">
      <one-icon class="mr-1" name="lock-open-outline"></one-icon>
      Login Methods
    </a>

    <a *ngIf="!isShopifyEmbedded"
      class="one-user-nav-menu-item"
      mat-menu-item
      href="#"
      (click)="logout($event)">
      <one-icon name="log-out-outline" class="mr-1"></one-icon>
      Log Out
    </a>

    <ng-container *ngIf="isAdmin || isAdminAsUser">
      <div *ngIf="!isShopifyEmbedded" class="one-user-nav-menu-divider"></div>
      <a
        class="one-user-nav-menu-item"
        mat-menu-item
        [routerLink]="'admin-panel'">
        <one-icon class="mr-1" name="shield"></one-icon>
        Admin Panel
      </a>
      <a *ngIf="isShopifyEmbedded && adminAsUser"
        class="one-user-nav-menu-item text-danger"
        mat-menu-item
        (click)="navigateToStandaloneAdmin($event)"
        [routerLink]="routeHeaderUrl.admin">
        <one-icon class="mr-1" name="log-out-outline"></one-icon>
        Admin Logout
      </a>
      <a *ngIf="!isShopifyEmbedded && adminAsUser"
        class="one-user-nav-menu-item text-danger"
        mat-menu-item
        (click)="adminLoginClicked('logout')">
        <one-icon class="mr-1" name="log-out-outline"></one-icon>
        Admin Logout
      </a>
    </ng-container>

  </div>

  <div class="one-user-nav-backdrop"></div>
</div>
