import { Injectable } from '@angular/core'
import { ShopifyService } from '../shopify.service'
import { UserService } from '../user.service'
import { filter, forkJoin, Subscription, take, switchMap, debounceTime, Subject, BehaviorSubject } from 'rxjs'
import { EnforcementWlService } from '../../../pages/admin-panel/enforcement-control/services/enforcement-wl.service'
import { EnforcementWhiteList, enforcementWLMappings } from '../../../pages/admin-panel/enforcement-control/models/enforcement-control.model'
import { PlansV3Identifier } from '../../../shared/models/subscription-plan.model'
import { LimitBlockerService } from './limit-blocker.service'

@Injectable({providedIn: 'root'})
export class ShopifyLimitEnforcementService {
  private subscription: Subscription = new Subscription()
  public markAsRead: boolean = false
  public enforced$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private skipPages = [
    'onboarding',
    'settings/billing',
  ]

  private initSubject = new Subject<void>()

  constructor(
    private enforcementWlService: EnforcementWlService,
    private limitBlockerService: LimitBlockerService,
    private shopifyService: ShopifyService,
    private userService: UserService,
  ) {
    this.subscription.add(
      this.initSubject.pipe(
        debounceTime(500), // Adjust the debounce time as needed
        switchMap(() => forkJoin([
          this.enforcementWlService.getEnforcementWhiteList(),
          this.shopifyService.activeKnownCustomersCount$.pipe(
            filter(akcs => akcs !== null),
            take(1)
          )
        ]))
      ).subscribe(([whiteList, factualAkc]) => {
        const planSubscription = this.userService.userInfo?.subscription
        const isBudding = this.userService.isLegacyBuddingPlanUser
        const subscribedToAkc = isBudding ? planSubscription?.max_active_contacts : planSubscription?.usage_plan?.max
        const isShopifyShop = this.userService.isShopifyShop
        const notStripePlan = ![PlansV3Identifier.NewCustomFree, PlansV3Identifier.NewCustomPaid].includes(planSubscription?.plan?.payment_gateway_plan_identifier as any)
        const isWhitelisted = this.isWhitelisted(whiteList)
        const exceedsLimit = factualAkc > subscribedToAkc
        // const contactsBasedPlan = planSubscription?.plan?.anchor_metric === 'contacts'
        const isv3BasicPlan = planSubscription?.plan?.payment_gateway_plan_identifier === PlansV3Identifier.Basic
        if (
          (
            isBudding && !isWhitelisted
          ) || (
            isv3BasicPlan &&
            factualAkc < 5000 &&
            exceedsLimit &&
            isShopifyShop &&
            notStripePlan &&
            !isWhitelisted
          )
        ) {
          const silent = this.skipPages.some((page) => window.location.pathname.includes(page))
          this.limitBlockerService.deactivateAll({
            factualAkc,
            subscribedToAkc,
            akcExceedsLimit: exceedsLimit,
            silent: silent,
          })
          this.enforced$.next(true)
        } else {
          this.enforced$.next(false)
        }
      })
    )
  }
  
  init() {
    this.initSubject.next()
  }

  private isWhitelisted(whiteList: EnforcementWhiteList): boolean {
    const wlKey = enforcementWLMappings['akc']
    let isWhitelisted = whiteList?.[wlKey]?.e
    const whiteListTTL = whiteList?.[wlKey]?.t
    if (whiteListTTL && whiteListTTL < Date.now()) {
      isWhitelisted = false
    }
    return isWhitelisted
  }

}
