<div class="L-App"
  [class._bar]="showNavbar"
  [class._is-embedded]="isShopifyEmbedded"
  [class._is-fullscreen]="(isFullscreen$ | async) === true"
  [class._nav-forced]="(bp$ | async)._1920.active"
  [class._nav-pinned]="(navPinned$ | async) && (bp$ | async)._660.active">

  <div class="L-App-Aside"
    [class._mobile-open]="(mobileMenu$ | async) && !(bp$ | async)._660.active"
    *ngIf="showNavbar" >
    <one-side-nav
      *ngIf="!showLoading"
    ></one-side-nav>
  </div>

  <div class="L-App-Body">

    <div class="L-App-Nav" *ngIf="showNavbar && !isFullScreen">
      <one-header></one-header>
    </div>

    <div class="L-App-Title" *ngIf="pageTitle">
      <one-page-title
        [title]="pageTitle"
        [backBtnUrl]="backBtnUrl"
      ></one-page-title>
    </div>

    <div class="L-App-Content" [class._with-navbar]="showNavbar || pageTitle" [class.mt-0]="!showNavbar && !pageTitle">
      <router-outlet (activate)="showLoading=false" (deactivate)="showLoading=true"></router-outlet>
      <!-- <div class="L-App-Aside _right"></div> -->
    </div>
  </div>

  @if (isAdmin) {
    <one-admin-tools></one-admin-tools>
  }

</div>
<pf-loading-overlay *ngIf="showLoading"></pf-loading-overlay>
<!--  used as a workaround to indicate that a resolver is fetching data -->
<pf-loading-overlay *ngIf="!showLoading" [filter]="{only: ['Navigation']}"></pf-loading-overlay>
